import { t } from "@lingui/macro";
import { PlayCircle, ReadCvLogo } from "@phosphor-icons/react";
import { Button, KeyboardShortcut, Separator } from "@reactive-resume/ui";
import { cn } from "@reactive-resume/utils";
import { motion } from "framer-motion";
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useKeyboardShortcut from "use-keyboard-shortcut";

import { Icon } from "@/client/components/icon";

import { ExplainerVideo } from "./explainer-video";

type Props = {
  className?: string;
};

const ActiveIndicator = ({ className }: Props) => (
  <motion.div
    initial={{ opacity: 0, x: -20 }}
    animate={{ opacity: 1, x: 0 }}
    className={cn(
      "size-1.5 animate-pulse rounded-full bg-info shadow-[0_0_12px] shadow-info",
      className,
    )}
  />
);

interface SidebarItem {
  path?: string;
  name: string;
  shortcut?: string;
  icon: React.ReactNode;
}

type SidebarItemProps = SidebarItem & {
  onClick?: () => void;
};

const SidebarItem = ({ path, name, shortcut, icon, onClick }: SidebarItemProps) => {
  const isActive = useLocation().pathname === path;

  return (
    <Button
      asChild
      size="lg"
      variant="ghost"
      onClick={onClick}
      className={cn(
        "h-auto justify-start px-4 py-3",
        isActive && "pointer-events-none bg-secondary-foreground/40 text-primary",
      )}
    >
      <Link to={path || ""}>
        <div className="mr-3">{icon}</div>
        <span>{name}</span>
        {!isActive && <KeyboardShortcut className="ml-auto">{shortcut}</KeyboardShortcut>}
        {isActive && <ActiveIndicator className="ml-auto" />}
      </Link>
    </Button>
  );
};

type SidebarProps = {
  setOpen?: (open: boolean) => void;
};

export const Sidebar = ({ setOpen }: SidebarProps) => {
  const navigate = useNavigate();
  const [showExplainerVideo, setShowExplainerVideo] = useState(false);

  useKeyboardShortcut(["shift", "r"], () => {
    navigate("/dashboard/resumes");
    setOpen?.(false);
  });

  useKeyboardShortcut(["shift", "s"], () => {
    navigate("/dashboard/settings");
    setOpen?.(false);
  });

  const sidebarItems: SidebarItem[] = [
    {
      path: "/dashboard/resumes",
      name: t`Resumes`,
      shortcut: "⇧R",
      icon: <ReadCvLogo />,
    },
  ];

  const closeExplainerVideo = () => setShowExplainerVideo(false);
  const openExplainerVideo = () => setShowExplainerVideo(true);

  return (
    <div className="flex h-full flex-col gap-y-4 bg-secondary">
      <ExplainerVideo show={showExplainerVideo} onClose={closeExplainerVideo} />
      <div className="ml-8 flex flex-col pl-8 pt-6 lg:ml-0">
        <Icon size={146} withText />
        <span className="ml-[27px]">{t`Resume Builder`}</span>
      </div>

      <Separator className="opacity-50" />

      <div className="grid gap-y-2">
        {sidebarItems.map((item) => (
          <SidebarItem {...item} key={item.path} onClick={() => setOpen?.(false)} />
        ))}
        <SidebarItem icon={<PlayCircle />} name={t`Explainer video`} onClick={openExplainerVideo} />
      </div>
    </div>
  );
};
